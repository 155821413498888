const config = {
  appname:'MUSIC DRIVE',
  serverurl: 'https://musicdrive.online/Laravel',
  googleLoginClientid: '860063763777-q07isovog1e148qov3to110cl0qe1us4.apps.googleusercontent.com',
  basename: '/free',
  defaultPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12
};

export default config;
