import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MusicDriveLogo from '../../assets/images/Applogo.png';
import Config from '../../config';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import AuthFooter from './AuthFooter';
import { Flip, toast } from 'react-toastify';
import { NavLink, useNavigate } from "react-router-dom";
import Axios from 'axios';
import { encrypt_data, decrypt_data } from '../../Utils';
import { jwtDecode } from "jwt-decode";


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
    const navigate = useNavigate();
    // const login = useGoogleLogin({
    //     onSuccess: (codeResponse) => setUser(codeResponse),
    //     onError: (error) => console.log('Login Failed:', error)
    // });
    const handleGoogleLogin = (response) => {
        var data = {
            login_agent: "google",
            credential: response.credential,
        }
        Axios.post(process.env.REACT_APP_SERVER_URL + '/api/google_signin', data).then(function (response) {
            if (response.data.status === "success") {
                toast.success(response.data.message);
                let user_token = response.data.data.token;
                sessionStorage.setItem('session_user_token', encrypt_data(user_token));
                let user_token_decoded = jwtDecode(user_token);

                sessionStorage.setItem('session_user_name', user_token_decoded.user_name);
                sessionStorage.setItem('session_profile_url', user_token_decoded.user_profile_img);
                navigate('/HomePage');
            } else {
                toast.warn(response.data.message, {
                    transition: Flip
                });
            }
        }).catch(function (error) {
            toast.warn(error.message);
        });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                {/* <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} > */}
                <img src={MusicDriveLogo} width={340} height={110} alt="App logo" />
                <Grid container spacing={1} sx={{ mt: 1 }}>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <GoogleOAuthProvider clientId={Config.googleLoginClientid}  >
                                <GoogleLogin onSuccess={handleGoogleLogin} text='continue_with' type="icon" size="large" />
                            </GoogleOAuthProvider>

                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 1 }}>
                        <Typography sx={{ textAlign: 'center' }}>OR</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >

                        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >

                        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >

                        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign In </Button>
                    </Grid>
                    <Grid item xs>
                        <NavLink to="#" style={{ fontSize: 15, color: '#1976d2' }} > Forgot password? </NavLink>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">Don't have an account? <NavLink to="/signup" style={{ color: '#1976d2' }} > Sign up </NavLink></Typography>

                    </Grid>

                </Grid>

                {/* </Box> */}
                <AuthFooter sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}