import React from 'react';
import { BrowserRouter, Routes as RouterRouters, Route } from "react-router-dom";
import Login from './Pages/Authentication/LogIn';
import SignUp from './Pages/Authentication/SignUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import UsersMainComp from './Components/UsersMainComp';

function Routes() {
    return (
        <BrowserRouter>
            <RouterRouters>

                <Route path="/" >
                    <Route index element={<Login />} />
                    <Route path="signup" element={<SignUp />} />
                    {/* <Route path="home" element={<UsersMainComp />} /> */}
                </Route>
                <Route path="/" >
                   
                    <Route path="HomePage" element={<UsersMainComp />} />
                </Route>
                {/* <Route path="*" element={<NoPage />} /> */}

                {/* <Route path="/teams" element={<Teams />}>
  <Route index element={<TeamsIndex />} />
  <Route path=":teamId" element={<Team />} />
</Route> */}
            </RouterRouters>
            <ToastContainer position="top-right" autoClose={5000} newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="light" />
        </BrowserRouter>
    )
}

export default Routes