import CryptoJS from "crypto-js";

const encrypt_data = (data) => {
    let secret_key = process.env.REACT_APP_CRYPTO_JS_SECRET;

    return CryptoJS.AES.encrypt(data, secret_key).toString();


}
const decrypt_data = (ciphertext) => {
    let secret_key = process.env.REACT_APP_CRYPTO_JS_SECRET;


    var bytes = CryptoJS.AES.decrypt(ciphertext, secret_key);
    return bytes.toString(CryptoJS.enc.Utf8);


}

export {encrypt_data,decrypt_data}