import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/joy/Snackbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MusicDriveLogo from '../../assets/images/Applogo.png';
import { NavLink, useNavigate } from "react-router-dom";
import Axios from 'axios';
import AuthFooter from './AuthFooter';


import { Flip, toast } from 'react-toastify';

import Config from '../../config';



const defaultTheme = createTheme();

export default function SignUp() {
  const [userFirstname, userFirstnameSet] = useState(false);
  const [userLastname, userLastnameSet] = useState(false);
  const [userEmail, userEmailSet] = useState(false);
  const [userEmailerrorMessage, userEmailerrorMessageSet] = useState("");
  const [userPassword, userPasswordSet] = useState(false);
  const [userPassworderrorMessage, userPassworderrorMessageSet] = useState("");
  const [userconfirmPassworderrorMessage, userconfirmPassworderrorMessageSet] = useState("");
  const [confirmuserPassword, confirmuserPasswordSet] = useState(false);
  const [otpPageBox, optPageBoxSet] = useState(false);
  const [otpVerifyError, otpVerifyErrorSet] = useState(false);
  const [userEmailValue, userEmailValueSet] = useState("");
  const [snakeBarstate, snakeBarstateSet] = useState(false);
  const [snakeBarvariant, snakeBarvariantSet] = useState("success");
  const [snakeBarMessage, snakeBarMessageSet] = useState("");
  const [snakeBaricon, snakeBariconSet] = useState(<CheckCircleOutlinedIcon />);
  const navigate = useNavigate();
  // console.log(userFirstname)

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleConfirmClickShowPassword = () => setShowConfirmPassword((show) => !show);
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  function isValidPassword(password) {
    var check_password_validation = false;
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (lowerCaseLetters.test(password) && upperCaseLetters.test(password) && numbers.test(password) && password.length >= 8) {
      check_password_validation = true;
    }
    return check_password_validation;
  }
  const checkpasswordValidation = (evt) => {
    if (evt.target.id === "user_password") {
      userPasswordSet(false);
      if (evt.target.value === "") {
        userPassworderrorMessageSet('Password is Required');
        userPasswordSet(true);
      } else if (!isValidPassword(evt.target.value)) {
        userPasswordSet(true);
        userPassworderrorMessageSet('Password Length is must be Minimum 8 Characters Atleast 1 uppercase,1 lowercase,1 number.');
      }
    } else if (evt.target.id === "confirm_user_password") {
      confirmuserPasswordSet(false);
      if (evt.target.value === "") {
        userconfirmPassworderrorMessageSet('Password is Required');
        confirmuserPasswordSet(true);
      } else if (!isValidPassword(evt.target.value)) {
        confirmuserPasswordSet(true);
        userconfirmPassworderrorMessageSet('Password Length is must be Minimum 8 Characters Atleast 1 uppercase,1 lowercase,1 number.');
      }
    }
  }
  const handleSubmit = () => {
    var check_validation = true;
    userFirstnameSet(false);
    userLastnameSet(false);
    userEmailSet(false);
    userPasswordSet(false);
    confirmuserPasswordSet(false);
    userPassworderrorMessageSet('Password is Required');
    userconfirmPassworderrorMessageSet('Confirm Password is Required');
    var user_firstname = document.getElementById('user_firstname').value;
    var user_lastname = document.getElementById('user_lastname').value;
    var user_email = document.getElementById('user_email').value;
    var user_password = document.getElementById('user_password').value;
    var confirm_user_password = document.getElementById('confirm_user_password').value;
    if (user_firstname === "") {
      userFirstnameSet(true);
      check_validation = false;
    }
    if (user_lastname === "") {
      userLastnameSet(true);
      check_validation = false;
    }
    if (user_email === "") {
      userEmailerrorMessageSet('Email Address is Required');
      userEmailSet(true);
      check_validation = false;
    } else if (user_email !== "") {
      if (!isValidEmail(user_email)) {
        userEmailSet(true);
        userEmailerrorMessageSet('Email Address is Invalid');
        check_validation = false;
      }
    }
    if (user_password === "") {
      userPasswordSet(true);
      check_validation = false;
    }
    if (confirm_user_password === "") {
      confirmuserPasswordSet(true);
      check_validation = false;
    }
    if (user_password !== "" && confirm_user_password !== "") {
      if (!isValidPassword(user_password) || !isValidPassword(confirm_user_password)) {
        if (!isValidPassword(user_password)) {
          userPasswordSet(true);
          userPassworderrorMessageSet('Password Length is must be Minimum 8 Characters Atleast 1 uppercase,1 lowercase,1 number.');
          check_validation = false;
        }
        if (!isValidPassword(confirm_user_password)) {
          confirmuserPasswordSet(true);
          userconfirmPassworderrorMessageSet('Password Length is must be Minimum 8 Characters Atleast 1 uppercase,1 lowercase,1 number.');
          check_validation = false;
        }
      } else {
        if (user_password !== confirm_user_password) {
          userPasswordSet(true);
          confirmuserPasswordSet(true);
          userPassworderrorMessageSet('Password is Not Match');
          userconfirmPassworderrorMessageSet('Confirm Password is Not Match with Password');
          check_validation = false;
        }
      }
    }
    if (check_validation) {
      var data = {
        user_firstname: user_firstname,
        user_lastname: user_lastname,
        user_email: user_email,
        user_password: user_password,
        confirm_user_password: confirm_user_password

      }
      Axios.post(Config.serverurl + '/api/register_user', data).then(function (response) {
        snakeBarstateSet(true);
        snakeBarMessageSet(response.data.message);
        if (response.data.status === "success") {
          document.getElementById('otp_confirm_code').value = "";
          userEmailValueSet(user_email);
          optPageBoxSet(true);
          snakeBarvariantSet("success");
          snakeBariconSet(<CheckCircleOutlinedIcon />);
        } else {
          snakeBarvariantSet("danger");
          snakeBariconSet(<ErrorOutlineOutlinedIcon />);
        }
      }).catch(function (error) {
        snakeBarstateSet(true);
        snakeBarMessageSet(error.message);
        snakeBarvariantSet("danger");
        snakeBariconSet(<ErrorOutlineOutlinedIcon />);
      });
    }

  };

  const otpVerification = () => {
    var user_otp = document.getElementById('otp_confirm_code').value;
    if (user_otp == "") {
      otpVerifyErrorSet(true);
    } else {
      otpVerifyErrorSet(false);
      var user_email = document.getElementById('user_email').value;
      var data = {
        user_otp: user_otp,
        user_email: user_email,
      }
      Axios.post(Config.serverurl + '/api/register_otp_verify', data).then(function (response) {
        snakeBarstateSet(true);
        snakeBarMessageSet(response.data.message);
        if (response.data.status === "success") {
          snakeBarvariantSet("success");
          snakeBariconSet(<CheckCircleOutlinedIcon />);
          navigate("/");
        } else {
          snakeBarvariantSet("danger");
          snakeBariconSet(<ErrorOutlineOutlinedIcon />);
        }
      }).catch(function (error) {
        snakeBarstateSet(true);
        snakeBarMessageSet(error.message);
        snakeBarvariantSet("danger");
        snakeBariconSet(<ErrorOutlineOutlinedIcon />);
      });
    }


  }

  return (
    <div>
      <Snackbar autoHideDuration={3000} open={snakeBarstate} color={snakeBarvariant} size="md" variant="soft" onClose={() => snakeBarstateSet(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        startDecorator={snakeBaricon}
        endDecorator={
          <Button onClick={() => snakeBarstateSet(false)} size="sm" variant="soft" color="success" > Dismiss </Button>
        }
      >
        {snakeBarMessage}
      </Snackbar>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <img src={MusicDriveLogo} width={340} height={110} alt="App logo" />
            {/* signup block */}
            <Box sx={{ display: (otpPageBox) ? 'none' : 'block', mt: 3 }}>
              <Grid container spacing={2}>
               
                <Grid item xs={12} sm={6}>
                  <TextField required error={userFirstname} helperText={(userFirstname && "First Name is Required")} name="firstName" fullWidth id="user_firstname" label="First Name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth id="user_lastname" required error={userLastname} helperText={(userLastname && "Last Name is Required")} label="Last Name" name="user_lastname" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth id="user_email" required error={userEmail} helperText={(userEmail && userEmailerrorMessage)} label="Email Address" name="user_email" autoComplete="email" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required error={userPassword} onKeyUp={(evt) => checkpasswordValidation(evt)} helperText={(userPassword && userPassworderrorMessage)} label="Password" id="user_password" type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required error={confirmuserPassword} onKeyUp={(evt) => checkpasswordValidation(evt)} helperText={(confirmuserPassword && userconfirmPassworderrorMessage)} label="Confirm Password" id="confirm_user_password" type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleConfirmClickShowPassword}
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} />
                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="I want to receive inspiration, marketing promotions and updates via email." />
              </Grid> */}
              </Grid>
              <Button fullWidth variant="contained" onClick={handleSubmit} sx={{ mt: 3, mb: 2 }} > Sign Up </Button>
              {/* <Grid container spacing={2}>

              </Grid> */}


            </Box>

            {/* otp verify block */}
            <Box sx={{ display: (otpPageBox) ? 'block' : 'none', mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}  >
                  <Box display="flex" justifyContent="center"   >
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Enter Verification code</Typography>
                  </Box>

                </Grid>
                <Grid item xs={12} >
                  <Typography style={{ fontSize: 15 }}>Enter the Verification code that we sent the mail to <b>{userEmailValue}</b>. </Typography>
                </Grid>
                <Grid item xs={12} >
                  <TextField required error={otpVerifyError} helperText={(otpVerifyError && "Verification Code is Required")} fullWidth id="otp_confirm_code" label="Verification code" />
                </Grid>


                <Grid item xs={6} >
                  <Button fullWidth variant="contained" onClick={() => optPageBoxSet(false)} sx={{ mt: 3, mb: 2 }} >Back to Sign</Button>
                </Grid>
                <Grid item xs={6} >
                  <Button fullWidth variant="contained" onClick={otpVerification} sx={{ mt: 3, mb: 2 }} >Verify</Button>
                </Grid>

              </Grid>


            </Box>


            <Grid container justifyContent="flex-end">
              <Grid item><Typography variant="string">Already have an account? </Typography>
                <NavLink to="/" style={{ fontSize: 15, color: '#1976d2' }}>Sign in </NavLink>
              </Grid>
            </Grid>
          </Box>
          <AuthFooter sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}