import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Config from '../../config';

function AuthFooter(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href={window.location.origin}>
      <span style={{ textTransform: 'lowercase', fontSize: '17px', fontWeight: 'bold' }}>{Config.appname}</span>
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  )
}

export default AuthFooter